import styled from 'styled-components'

const Wrapper = styled.section`

  .SankeyChart {

    width: 90%;
        
  }

  @media (max-width: 400px) {

    .SankeyChart {



    }

  }
`
export default Wrapper
