import {JobsContainer, SearchContainer } from '../../components'

export const AllJobs = () => {
  return (
    <>
      <SearchContainer />
      <JobsContainer />
    </>
  )
}

export default AllJobs